// @ts-nocheck
import axios from "axios";

const BACKEND_URL = 'https://api-kortros.pairmax.digital/telegram/estate/application';
const ACTIVITY_BACKEND_URL = 'https://api-kortros.pairmax.digital/telegram/activity'
const ANALYTICS_BACKEND_URL = 'https://api-kortros.pairmax.digital/telegram/analytics'
export default class TelegramAPI {
    static async sendConsultation(guid: string, userId: string | number, project: string, phone: string) {
        if (typeof Comagic !== "undefined") {
            Comagic.addOfflineRequest({
                phone: phone,
                user_fields: {
                    guid: guid,
                    userId: userId,
                    project: project,
                }
            });
            console.log("Данные отправлены в Comagic");
        } else {
            console.error("Comagic не подключен или недоступен");
        }
        console.log(phone)

        try {
            const response = await axios.post(BACKEND_URL, JSON.stringify({
                guid: guid,
                userId: userId,
                project: project,
                phone: phone
            }));

            // Отправка события в dataLayer
            if (typeof window.dataLayer !== "undefined") {
                window.dataLayer.push({
                    event: "success_callback_form",
                    guid: guid,
                    userId: userId,
                    project: project,
                    phone: phone
                });
                console.log("Событие success_callback_form отправлено в dataLayer");
            } else {
                console.warn("dataLayer не определен");
            }

            return response.data;
        } catch (error) {
            console.error("Ошибка при отправке данных:", error);
            // throw error;
        }
    }
    static async sendUserAddCallback(userId: string | number, guid: string) {
        try {
            const response = await axios.post(`${ACTIVITY_BACKEND_URL}/user/add/callback`, JSON.stringify({
                userId: userId,
                guid: guid
            }));
            console.log(`Пользователь: ${userId} - Поделился с вами`);
            return response.data;
        } catch (error) {
            console.error("Ошибка при отправке данных:", error);
            // throw error;
        }
    }
    static async sendOpenApplication(userId: string | number) {
        try {
            const response = await axios.post(`${ACTIVITY_BACKEND_URL}/application/open`, JSON.stringify({
                userId: userId,
            }));
            console.log(`Пользователь: ${userId} - Вошёл`);
            return response.data;
        } catch (error) {
            console.error("Ошибка при отправке данных:", error);
            // throw error;
        }
    }
    static async sendPhoneAddApplication(userId: string | number, phone: string) {
        console.log(phone)
        try {
            const response = await axios.post(`${ACTIVITY_BACKEND_URL}/user/add/phone`, JSON.stringify({
                userId: userId,
                phone: phone
            }));
            console.log(`Пользователь: ${userId} - Поделился телефоном: ${phone}`);
            return response.data;
        } catch (error) {
            console.error("Ошибка при отправке данных:", error);
            // throw error;
        }
    }
    static async sendPhoneRefuseApplication(userId: string) {
        try {
            const response = await axios.post(`${ACTIVITY_BACKEND_URL}/user/refuse/phone`, JSON.stringify({
                userId: userId,
            }));
            console.log(`Пользователь: ${userId} - Отказался делится телефоном`);
            return response.data;
        } catch (error) {
            console.error("Ошибка при отправке данных:", error);
            // throw error;
        }
    }
    static async sendOpenCardApplication(userId: string | number, guid: string) {
        try {
            const response = await axios.post(`${ACTIVITY_BACKEND_URL}/user/add/viewing`, JSON.stringify({
                userId: userId,
                guid: guid
            }));
            console.log(`Пользователь: ${userId} - Открыл карточку: ${guid}`);
            return response.data;
        } catch (error) {
            console.error("Ошибка при отправке данных:", error);
            // throw error;
        }
    }
    static async sendUTMAnalytics(userId: string | number, startapp: string, utm: string) {
        try {
            const response = await axios.post(`${ANALYTICS_BACKEND_URL}/yautm/add`, JSON.stringify({
                userId: userId,
                startapp: startapp,
                utm: utm
            }));
            console.log(`Пользователь: ${userId} - Посетил страницу с: ${startapp} и utm: ${utm}`);
            return response.data;
        } catch (error) {
            console.error("Ошибка при отправке данных:", error);
            // throw error;
        }
    }
}
