import React, {useEffect, useState} from 'react';
import AllObjectsPage from "./pages/all-objects-page/all-objects-page";
import {AppContext} from "./context/context";
import {TApartment, TProject} from "./types/project";
import ProjectAPI from "./api/project";
import Loader from "./components/ui/loader/loader";
import ApartmentPage from "./pages/apartment-page/apartment-page";
import AgreementWindow from "./components/agreement-window/agreement-window";
import HeaderLayout from "./components/header-layout/header-layout";
import useTelegram from './hooks/useTelegram';
import { getDefaultOption, IReadyTermOption } from './types/readyTermOptions';
import { getMinMaxValue, TMinMaxValue } from './types/minMaxSlider';
import TelegramAPI from './api/telegram';
import Cookies from "js-cookie"
const UTM_TAGS: any = {
    "tg_ravnovesiye": 'utm_source=telegram&utm_medium=cpm&utm_campaign=samedia',
    "tg_ravnovesiye-miniapp_InEstate-Parent-MSK_BR10_LjN8Jz6Pv": 'utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|miniapp&utm_content=(In)Estate+Parent_MSK&utm_term=BR10&erid=LjN8Jz6Pv',
    "tg_ravnovesiye-miniapp_TopEstate-RF_BR10_LjN8Jz6Pv": 'utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|miniapp&utm_content=(Top)Estate_RF&utm_term=BR10&erid=LjN8Jz6Pv',
    "tg_ravnovesiye-miniapp_InEstate-MSK-MO_BR10_LjN8Jz6Pv": 'utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|miniapp&utm_content=(In)Estate_MSK+MO&utm_term=BR10&erid=LjN8Jz6Pv',
    "tg_ravnovesiye-miniapp_CHCompet-RF_BR10_LjN8Jz6Pv": 'utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|miniapp&utm_content=(CH)Compet_RF&utm_term=BR10&erid=LjN8Jz6Pv',
    "tg_ravnovesiye-miniapp_CHClassified-RF_BR10_LjN8Jz6Pv": 'utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|miniapp&utm_content=(CH)Classified_RF&utm_term=BR10&erid=LjN8Jz6Pv',
    "tg_ravnovesiye-miniapp_CHEstate-RF_BR10_LjN8Jz6Pv": 'utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|miniapp&utm_content=(CH)Estate_RF&utm_term=BR10&erid=LjN8Jz6Pv',
    "tg_ravnovesiye-miniapp_CHAudCompet-MSK_BR10_LjN8Jz6Pv": 'utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|miniapp&utm_content=(CHAud)Compet_MSK&utm_term=BR10&erid=LjN8Jz6Pv',
    "tg_ravnovesiye-miniapp_CHAudClassified-MSK_BR10_LjN8Jz6Pv": 'utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|miniapp&utm_content=(CHAud)Classified_MSK&utm_term=BR10&erid=LjN8Jz6Pv',
    "tg_ravnovesiye-miniapp_CHFaceEstate-RF_BR10_LjN8Jz6Pv": 'utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|miniapp&utm_content=(CH)FaceEstate_RF&utm_term=BR10&erid=LjN8Jz6Pv',
    "tg_ravnovesiye-miniapp_TopEconomy-RF_BR10_LjN8Jz6Pv": 'utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|miniapp&utm_content=(Top)Economy_RF&utm_term=BR10&erid=LjN8Jz6Pv',
    "tg_ravnovesiye-miniapp_InInvest-MSK-MO_BR10_LjN8Jz6Pv": 'utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|miniapp&utm_content=(In)Invest_MSK+MO&utm_term=BR10&erid=LjN8Jz6Pv',
    "tg_ravnovesiye-miniapp_InAll-MSK-MO_BR10_LjN8Jz6Pv": 'utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|miniapp&utm_content=(In)All_MSK+MO&utm_term=BR10&erid=LjN8Jz6Pv',
    "tg_ravnovesiye-miniapp_InEstate-Invest-MSK-MO_BR10_LjN8Jz6Pv": 'utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|miniapp&utm_content=(In)Estate+Invest_MSK+MO&utm_term=BR10&erid=LjN8Jz6Pv',
}

const UTM_TAGS_BOT: any = {
    464: "utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|bot&utm_content=(In)Estate+Parent_MSK&utm_term=BR8&erid=LjN8KTdor",
    465: "utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|bot&utm_content=(Top)Estate_RF&utm_term=BR8&erid=LjN8KTdor",
    466: "utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|bot&utm_content=(In)Estate_MSK+MO&utm_term=BR8&erid=LjN8KTdor",
    467: "utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|bot&utm_content=(CH)Compet_RF&utm_term=BR8&erid=LjN8KTdor",
    468: "utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|bot&utm_content=(CH)Classified_RF&utm_term=BR8&erid=LjN8KTdor",
    469: "utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|bot&utm_content=(CH)Estate_RF&utm_term=BR8&erid=LjN8KTdor",
    470: "utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|bot&utm_content=(CHAud)Compet_MSK&utm_term=BR8&erid=LjN8KTdor",
    471: "utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|bot&utm_content=(CHAud)Classified_MSK&utm_term=BR8&erid=LjN8KTdor",
    472: "utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|bot&utm_content=(CH)FaceEstate_RF&utm_term=BR8&erid=LjN8KTdor",
    473: "utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|bot&utm_content=(Top)Economy_RF&utm_term=BR8&erid=LjN8KTdor",
    474: "utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|bot&utm_content=(In)Invest_MSK+MO&utm_term=BR8&erid=LjN8KTdor",
    475: "utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|bot&utm_content=(In)All_MSK+MO&utm_term=BR8&erid=LjN8KTdor",
    476: "utm_source=telegram&utm_medium=cpm&utm_campaign=samedia|ravnovesiye|bot&utm_content=(In)Estate+Invest_MSK+MO&utm_term=BR8&erid=LjN8KTdor",
}

function App() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [projects, setProjects] = useState<TProject[]>([]);
    const [selectedProjectIndex, setSelectedProjectIndex] = useState<number>(0);
    const [selectedApartment, setSelectedApartment] = useState<TApartment | null>(null);
    const [apartmentList, setApartmentList] = useState<TApartment[]>([]);
    const [buildingList, setBuildingList] = useState<any>([]);
    const [userHasAgreed, setUserHasAgreed] = useState<boolean>(false);
    const [floorList, setFloorList] = useState<any>([]);
    const [user, setUser] = useState<any>(null);
    const [phone, setPhone] = useState<string>('');

    const [maxRoomsCount, setMaxRoomsCount] = React.useState<number>(0);
    const [readyTermOptions, setReadyTermOptions] = React.useState<IReadyTermOption[]>([])
    const [selectedRoomCountOptions, setSelectedRoomCountOptions] = React.useState<string[]>([])
    const [minMaxPriceValues, setMinMaxPriceValues] = React.useState<TMinMaxValue>(getMinMaxValue(0, 0))
    const [readyTermSelectedOption, setReadyTermSelectedOption] = React.useState<IReadyTermOption>(getDefaultOption())
    const [minMaxAreaValues, setMinMaxAreaValues] = React.useState<TMinMaxValue>(getMinMaxValue(0, 0))

    const { getUserInfo, telegram } = useTelegram()

    const fetchProjects = async () => {
        try {
            const response = await ProjectAPI.getProjectList();
            setProjects(response);
            setIsLoading(false);
        } catch (error) {
            console.error('Something went wrong: ', error);
        }
    };

    const getExpiredDate = () => {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        return date
    }

    const sendUTMInYandex = (UTM_TAGS: any) => {
        const tags = UTM_TAGS.split('&').map((tag: any) => tag.split('=')).reduce((acc: any, [key, value] : any) => ({ ...acc, [key]: value }), {})
        // @ts-ignore
        if(ym) {
            console.log(tags)
            // @ts-ignore
            ym(91302277, "params", tags)
        }
    }

    useEffect(() => {
        fetchProjects();
        setUser(getUserInfo());
        // if(localStorage.getItem('userHasAgreed') === 'true') {
        //     setUserHasAgreed(true)
        // }
        // @ts-ignore
        if(telegram) {
            const phone = localStorage.getItem('phone');
            const startParam = telegram.initDataUnsafe.start_param || 'tg_ravnovesiye-miniapp_InEstate-Parent-MSK_BR10_LjN8Jz6Pv'
            if(getUserInfo()) TelegramAPI.sendOpenApplication(getUserInfo().id)
            if(startParam) sendUTMInYandex(UTM_TAGS[startParam])
            if(startParam && getUserInfo()) TelegramAPI.sendUTMAnalytics(getUserInfo().id, startParam, UTM_TAGS[startParam])
            if(phone) {
                // @ts-ignore
                setPhone(phone)
            }
        }
    }, []);

    // scrolls to up when user chooses another apartment
    useEffect(() => {
        if(selectedApartment && !!getUserInfo()) TelegramAPI.sendOpenCardApplication(getUserInfo().id, selectedApartment?.id)
    }, [selectedApartment]);



    return (
        <AppContext.Provider value={{
            isLoading,
            setIsLoading,
            projects,
            setProjects,
            selectedProjectIndex,
            setSelectedProjectIndex,
            selectedApartment,
            setSelectedApartment,
            apartmentList,
            setApartmentList,
            buildingList,
            setBuildingList,
            floorList,
            setFloorList,
            user,
            setUser,
            setMaxRoomsCount,
            maxRoomsCount,
            setReadyTermOptions,
            readyTermOptions,
            readyTermSelectedOption,
            setReadyTermSelectedOption,
            minMaxPriceValues,
            setMinMaxPriceValues,
            minMaxAreaValues,
            setMinMaxAreaValues,
            selectedRoomCountOptions,
            setSelectedRoomCountOptions,
            phone,
            setPhone
        }}>
            {
                <>
                    {
                        selectedApartment ?
                            <ApartmentPage/> : null
                    }
                    <AllObjectsPage/>
                    <Loader isActive={isLoading}/>
                </>
            }
        </AppContext.Provider>
    );
}


export default App;
